<template>
  <div class="product-detail">
    <div class="product-container clearfix">
      <div class="product-container-list">
        <div class="big-img">
          <div
            :style="{
              backgroundImage: 'url(' + thisData.titleImg[imgId] + ')',
            }"
          ></div>
          <!-- <div >
 {{imgStyle}}
    </div> -->
        </div>
        <div class="img-copy-list">
          <span class="prev" @click="nextImg('prev')"></span>
          <div class="db">
            <div class="tempWrap">
              <ul id="clickBox">
                <li
                  :class="{ on: imgId == key }"
                  v-for="(item, key) in thisData.titleImg"
                  @mousemove="changeImgId(key)"
                  @click="changeImgId(key)"
                  :key="key"
                  :style="{ backgroundImage: 'url(' + item + ')' }"
                ></li>
              </ul>
            </div>
          </div>
          <span class="next" @click="nextImg"></span>
        </div>
      </div>
      <div class="right-info">
        <h1 v-html="thisData.title">27寸横竖屏裸眼3D一体机</h1>
        <p v-html="thisData.concent">
          27寸裸眼3DLCD显示屏采用棱镜技术通过全贴合使得液晶和棱镜全面贴合，保证杰出的3D体验，串扰低于20%，一体机的设计使安装使用更为便捷。在影视娱乐、产品展示等领域被广泛使用。其强烈逼真的立体效果，细腻的质感，颇得消费者喜爱。
        </p>
        <router-link
          :to="{ path: '/product/index', query: { id: this.$route.query.id } }"
          >Back to list</router-link
        >
      </div>
    </div>
    <div class="product-tab">
      <div class="product-tab-info">
        <p v-for="(item, key) in thisData.p" :key="key" v-html="item">
          优质的出屏效果
        </p>
        <p></p>
        <p><img :src="thisData.img" alt="" /></p>
      </div>
      <h2>Product parameters</h2>
      <div class="table-info">
        <table
          width="100%"
          border="0"
          cellspacing="0"
          cellpadding="0"
          class="ke-zeroborder"
        >
          <tbody>
            <tr v-for="(item, key) in thisData.table" :key="key">
              <td
                class="th-1"
                v-for="(item1, key1) in item"
                :key="key1"
                v-html="item1"
              ></td>
              <!-- <td v-html="item">27" 单机</td>
 <td class="th-1">亮度</td>
 <td>350 cd/m2 (Typical)</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import data from "@/assets/json/product.json";
export default {
  name: "WeichaoProductdetail",

  data() {
    return {
      imgStyle: {
        img: data,
      },
      imgData: [
        require("../../assets/img/product/20190806145909264.jpg"),
        require("../../assets/img/product/20190806145911311.jpg"),
        require("../../assets/img/product/20190807142713772.jpg"),
        require("../../assets/img/product/20190806145909264.jpg"),
      ],
      // imgData: data.product.home1.titleImg,

      imgId: "0",
      product: {
        home1: {
          title: "85 Inches Glasses-free 3D AD Player",
          detail: "123",
          titleImg: [require("../../assets/img/home/20190807114547020.jpg")],
          concent:
            "Offers better vision with bigger size of the panel. With built-in operation platform and system, it allows plug and play, the ready to be used content updating and scheduling system make itself an ideal equipment for commerical message  delievery.",
          p: [
            "Highlights",
            "Biggersize, better effects.",
            "4Kresolution, amazing 3D effects.",
            "Customizableappearance and brightness.",
          ],
          img: require("../../assets/img/product/20190806150235920.png"),
          table: [
            [
              "Basic display",
              "85 Display",
              "Brightness",
              "500 cd/m2 (Typical)",
            ],
            ["Types", "Portrait Stand", "Weight", "325KG"],
            ["Shell", "Metal&amp;Tempered Glass", "Visual number", "4 views"],
            ["Color", "Black", "3D raster type", "Lenticular"],
            ["Product Size", "85 inch", "Optimum line of sight", "2.5m～4.0m"],
            [
              "Resolution",
              "2160 x 3840",
              "Power",
              "Input – 100 ~ 240V AC, 50 ~ 60Hz",
            ],
            ["Display area", "1872(H) x 1053(V) mm", "Power", "710w"],
            [
              "Pixel",
              "RGB vertical stripe",
              "I/O",
              "1x HDMI Input , 1x USB2.0 port , 1x DVI, 1x VGA , 1x audio out",
            ],
          ],
        },
        home2: {
          title: "42 inches Glasses-free 3D Smart Display",
          detail: "123",
          titleImg: [
            require("../../assets/img/home/Floor002367-img2.jpg"),
            require("../../assets/img/home/Floor002367-img3.jpg"),
          ],
          concent:
            "42 inches glasses-free 3D LCD landscape display integrates with android platform,provides outstanding 3D effects and zero spinning sensation.It can be used for advertising, digital signage, catering and many other business occasions.",
          p: [
            "Highlights",
            "Outstanding3D effects.",
            "All-in-onedesign, plug and play.",
            "Applyfor various business scenarios.",
            "Supportinternet connection and centralized content management.",
          ],
          img: "",
          table: [
            [
              "Basic display",
              "42  Display",
              "Brightness",
              "400 cd/m2 (Typical)",
            ],
            ["Types", "Landscape", "Weight", "35KG"],
            ["Shell", "Metal", "Visual number", ""],
            ["Color", "Black", "3D raster type", "Lenticular"],
            ["Product Size", "42 inch", "Optimum line of sight", "3.0m～8.0 m"],
            [
              "Resolution",
              "1920 x 1080",
              "Power",
              "Input – 100 ~ 240V AC, 50 ~ 60Hz",
            ],
            ["Display area", "930(H) x 523(V) mm", "Power", ""][
              ("Pixel",
              "RGB vertical stripe",
              "I/O",
              "1x HDMI Input , 1x USB2.0 port , 1x DVI, 1x VGA , 1x audio out")
            ],
          ],
        },
        home3: {
          title: "32 inches Glasses-free 3D Smart Display",
          detail: "123",
          titleImg: [require("../../assets/img/home/Floor002367-img4.jpg")],
          concent:
            "An ideal entry level products with fully embedded playback platform and software.Upon that, it perfect fits for window display, in-store product promotion and many other business scenarios.",
          p: [
            "Highlights",
            "Flexibleusage for many different scenarios.",
            "Outstanding3D effect.",
            "Androidall-in-one design, plug and play.",
            "Customizableappearance and brightness.",
          ],
          img: "",
          table: [
            [
              "Basic display",
              "Brightness",
              "32' Display",
              "1000 cd/m2 (high brigtness)",
            ],

            ["Types", "Landscape", "Weight", "21KG"],

            ["Shell", "Metal", "Visual number", "8 views"],
            ["Color", "Black", "3D raster type", "Lenticular"],

            ["Product Size", "32 inch", "Optimum line of sight", "1 m～3.0 m"],
            [
              "Resolution",
              "1920 x 1080",
              "Power",
              "Input – 100 ~ 240V AC, 50 ~ 60Hz",
            ],
            ["Display area", "708.5(H) x 398.5(V) mm", "Power", "155w"],
            [
              "Pixel",
              "RGB vertical stripe",
              "I/O",
              "1x HDMI Input , 1x USB2.0 port , 1x DVI, 1x VGA , 1x audio out",
            ],
          ],
        },
        home4: {
          title: "55 inches Glasses-free 3D Smart Display",
          detail: "123",
          titleImg: [require("../../assets/img/home/Floor002367-img3.jpg")],
          concent:
            "55 inches glasses-free 3D LCD landscape display integrates with Android platform,provides outstanding 3D effects and zero spinning sensation.It can be used for advertising, digital signage, catering and many other business occasions.",
          p: [
            "Highlights",
            "Smallersize better usage.",
            "Outstand4K glasses-free 3D experience.",
            "All-in-onedesign, plug and play.",
            "Supportinternet connection",
            "andcloud based content management.",
          ],
          img: "",
          table: [
            [
              "Basic display",
              "55' Display",
              "Brightness",
              "400 cd/m2 (Typical)",
            ],

            ["Types", "Landscape", "Weight", "45KG"],

            ["Shell", "Metal", "Visual number", "9 views"],

            ["Color", "Black", "3D raster type", "Lenticular"],

            ["Product Size", "55 inch", "Optimum line of sight", "3.0m～8.0 m"],

            [
              "Resolution",
              "3840 x 2160",
              "Power",
              "Input – 100 ~ 240V AC, 50 ~ 60Hz",
            ],

            ["Display area", "1209.6(H) x 680.4(V) mm", "Power", "200w"],

            [
              "Pixel",
              "RGB vertical stripe",
              "I/O",
              "1x HDMI Input , 1x USB2.0 port , 1x DVI, 1x VGA , 1x audio out",
            ],
          ],
        },
        home5: {
          title: "98' Glasses-Free 3D Display",
          detail: "123",
          titleImg: [require("../../assets/img/product/20190813175135634.jpg")],
          concent: "Model：98'Specification：98' 4K 98' 4K - Landscape",
          p: [],
          img: "",
          table: [
            [
              "Basic display",
              "98' Display",
              "Brightness",
              "500 cd/m2 (Typical)",
            ],
            ["Types", "Landscape", "Weight"],
            ["Shell", "Metal", "Visual number", "9 views"],
            ["Color", "Black", "3D raster type", "Lenticular"],
            ["Product Size", "98 inch", "Optimum line of sight", "3.0m～8.0 m"],
            [
              "Resolution",
              "3840 x 2160",
              "Power",
              "Input – 100 ~ 240V AC, 50 ~ 60Hz",
            ],
            ["Display area", "2158.85(H) x 1214.35(V) mm", "Power", "''"],
            [
              "Pixel",
              "RGB vertical stripe",
              "I/O",
              "1x HDMI Input , 1x USB2.0 port , 1x DVI, 1x VGA , 1x audio out",
            ],
          ],
        },
        de2: {
          title: "65 Inches Glasses-free 3D AD Player",
          detail: "123",
          titleImg: [require("../../assets/img/product/20190813144911572.jpg")],
          concent:
            "An ideal AD player for anyone who is willing to upgrade existing advertisement facility.With built-in 3D playback plat-form and CMS system, that makes itself appropriate for public space like airport,luxury hotel, stations to deliev-er commerical .",
          p: [
            "Basic display",
            "All-in-one design, plug and play.",
            "4K resolution, amazing 3D effects.",
            "Customizable appearance and brightness.",
            "Support internet connection andcentralizd display and content.",
          ],
          img: "",
          table: [
            [
              "Basic display",
              "65' Display",
              "Brightness",
              "400 cd/m2 (Typical)",
            ],
            ["Types", "Portrait Stand", "Weight", "150KG"],
            ["Shell", "Metal&amp;Tempered Glass", "Visual number", "4 views"],
            ["Color", "Black", "3D raster type", "Lenticular"],
            ["Product Size", "65 inch", "Optimum line of sight", "2.5m～4.0m"],
            [
              "Resolution",
              "3840 x 2160",
              "Power",
              "Input – 100 ~ 240V AC, 50 ~ 60Hz",
            ],
            ["Display area", "1428.48(H) x 803.52(V) mm", "Power", "230w"],
            [
              "Pixel",
              "RGB vertical stripe",
              "I/O",
              "1x HDMI Input , 1x USB2.0 port , 1x DVI, 1x VGA , 1x audio out",
            ],
          ],
        },
        de1: {
          title: "27 Inches Glasses-free 3D AD Player",
          detail: "123",
          titleImg: [
            require("../../assets/img/product/20190806145909264.jpg"),
            require("../../assets/img/product/20190806145911311.jpg"),
            require("../../assets/img/product/20190807142713772.jpg"),
            require("../../assets/img/product/20190806145909264.jpg"),
          ],
          concent:
            "27 inches glasses-free 3D AD player provides outstanding 3D effects. It is an ideal product for advertisement and supporting both landscape and portrait modes. With embedded content management system, it allows centralized content updating, schedulin",
          p: [
            "Basic display",
            "All-in-one design,plug,and play.",
            "Dual mode,both landscape,and portrait.",
            "Support both Android and Windows platform.",
            "Support internet connection and centralizedoperation.",
          ],
          img: "",
          table: [
            [
              "Basic display",
              "27' Display",
              "Brightness",
              "350 cd/m2 (Typical)",
            ],

            ["Types", "Landscape/Portrait", "Weight", "12KG"],

            ["Shell", "Metal", "Visual number", "4 views"],

            ["Color", "Black", "3D raster type", "Lenticular"],

            ["Product Size", "27 inch", "Optimum line of sight", "1 m～2.5 m"],

            [
              "Resolution",
              "3840 x 2160",
              "Power",
              "Input – 100 ~ 240V AC, 50 ~ 60Hz",
            ],

            ["Display area", "335.664(H) x 596.736(V) mm", "Power", "140w"],

            [
              "Pixel",
              "RGB vertical stripe",
              "I/O",
              "1x HDMI Input , 1x USB2.0 port , 1x DVI, 1x VGA , 1x audio out",
            ],
          ],
        },
        vo1: {
          title: "3D LCD Video Wall",
          detail: "123",
          titleImg: [
            require("../../assets/img/product//vo/20190813160029228.jpg"),
            require("../../assets/img/product//vo/20190813160051056.jpg"),
            require("../../assets/img/product//vo/20190813160255541.png"),
            require("../../assets/img/product//vo/20190813160504931.png"),
          ],
          concent: "Model：55inch 3D LCD Video WallSpecification：55inch FHD",
          p: [
            "1. Raster Correction Technology",
            " Through raster correction technology and pixel by pixel collaboration, video wall successfully avoid image",
            "  tear, morie, cross talk and at the same time ensure perfect 3D effect.",
            "2. Edge Fusion Technology",
            " Edge fusion technology aims to eliminate anamorphose, image tear that are caused by display assembly. Through",
          ],
          img: [require("../../assets/img/product//vo/video_wall-2.jpg")],
          table: [
            ["Display", "LCD Display", "Display Size&nbsp;&nbsp;", "55inch"],

            [
              "Resolution",
              "FHD (1920x1080)",
              "Brightness",
              "350 cd/m2 (Typical)",
            ],

            [
              "Display Area",
              "1209.6(H)x680.4(V)mm",
              "Product Size",
              "1214.5(H)x685.3(V)mm",
            ],

            [
              "Bezel Gap",
              "4.9mm (Typical)",
              "Contrast Ratio",
              "4000:1 (Typical)",
            ],

            ["Viewing Angle", "120", "Response", "8ms (Typical)"],

            [
              "Weight",
              "70 kgs (Reference)",
              "3D Grating Type",
              "Lenticular Grating",
            ],

            [
              "Best Viewing",
              "10~15m",
              "3D Content Format",
              "Multi-stream, Multi-tile, 2D+Z, SBS, etc.",
            ],
          ],
        },
        ip1: {
          title: "iPhone 11 pro max",
          detail: "123",
          titleImg: [
            require("../../assets/img/product/pro/20191012152132306.png"),
          ],
          concent: "iPhone 11 pro max",
          p: [],
          img: [require("../../assets/img/product/pro/20191012152132306.png")],
          table: [],
        },
        ip2: {
          title: "iPhone 11 pro",
          detail: "123",
          titleImg: [
            require("../../assets/img/product/pro/20191012154145470.png"),
          ],
          concent: "iPhone 11 pro max",
          p: [],
          img: [require("../../assets/img/product/pro/20191012154145470.png")],
          table: [],
        },
        ip3: {
          title: "iPhone 11",
          detail: "123",
          titleImg: [
            require("../../assets/img/product/pro/20191012154230344.png"),
          ],
          concent: "iPhone 11",
          p: [],
          img: [require("../../assets/img/product/pro/20191012154230344.png")],
          table: [],
        },
        ip4: {
          title: "iPhone 11",
          detail: "123",
          titleImg: [
            require("../../assets/img/product/pro/20191012154230344.png"),
          ],
          concent: "iphone xr",
          p: [],
          img: [require("../../assets/img/product/pro/20191012154230344.png")],
          table: [],
        },
        ip5: {
          title: "iphone xr",
          detail: "123",
          titleImg: [
            require("../../assets/img/product/pro/20191012155112963.png"),
          ],
          concent: "iphone xr",
          p: [],
          img: [require("../../assets/img/product/pro/20191012155112963.png")],
          table: [],
        },
        ip6: {
          title: "iPhone xs max",
          detail: "123",
          titleImg: [
            require("../../assets/img/product/pro/20191012155558054.png"),
          ],
          concent: "iPhone xs max",
          p: [],
          img: [require("../../assets/img/product/pro/20191012155558054.png")],
          table: [],
        },
        rp1: {
          title: "Reality Player 2.0",
          detail: "123",
          titleImg: [require("../../assets/img/product/20190813155319150.jpg")],
          concent: "Reality Player 2.0",
          p: [],

          table: [
            ["3D Player", "RealityPlayer 2.0"],

            ["Contentformat		", "2D,Multi-Stream,Multi-Tile, 2D+Z/D, etc"],

            ["Configurableitems", "3DVolume and Zero plane"],
          ],
        },
      },
      thisData: "",
      routeID: this.$route.query.itemId,
    };
  },
  created() {
    this.thisData = this.product[this.routeID];
    console.log(this.routeID);
  },
  mounted() {},

  methods: {
    nextImg(dom) {
      let clickBox = document.querySelector("#clickBox");
      let OnLength = clickBox.children.length;
      if (dom == "prev") {
        //上一页 到开头跳最后
        if (this.imgId == 0) {
          this.imgId = OnLength - 1;
        } else {
          this.imgId--;
        }
      } else {
        //下一页 到尽头归一

        if (this.imgId == OnLength - 1) {
          this.imgId = 0;
        } else {
          this.imgId++;
        }
      }
    },
    changeImgId(item) {
      this.imgId = item;
    },
  },
  mounted() {},
  watch: {
    imgId(id) {
      let clickBox = document.querySelector("#clickBox");
      //元素高度
      let Theight = clickBox.firstChild.offsetHeight;
      if (this.imgId > 2) {
        //往上位移
        clickBox.style.top = (this.imgId - 2) * -Theight + "px";
      }
      if (this.imgId == 0) {
        clickBox.style.top = 0;
        clickBox.parentElement.scrollTop  = 0
      }
      //选中的图片边框等样式
      let thisClick = document.querySelector("#clickBox").children;
      document.querySelector(".on").classList.remove("on");
      thisClick[id].classList.add("on");
    },
  },
};
</script>

<style lang="less" scoped>
.product-detail {
  padding: 3% 0 2%;
  .product-container {
    max-width: 1200px;
    margin: 0 auto;
    .product-container-list {
      float: left;
      width: 55.6%;
      position: relative;
      height: 474px;
      .big-img {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 80%;
        //图片
        > div {
          //   background: url("~@/assets/img/product/20190806145909264.jpg");
          border: 1px solid #dcdcdc;
          background-size: contain;
          position: absolute;
          left: 0;
          width: 100%;
          top: 0;
          height: 100%;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
      .img-copy-list {
        padding: 42px 0 42px 82.9%;
        span {
          position: absolute;
          width: 17.1%;
          height: 40px;
          right: 0;
          cursor: pointer;
        }
        .prev {
          top: 0;
          background: url("~@/assets/img/product/RighInfo002254-btn_prev.png")
            no-repeat center center/auto 50%;
        }
        .next {
          bottom: 0;
          background: url("~@/assets/img/product/RighInfo002254-btn_next.png")
            no-repeat center center/auto 50%;
        }
        .db {
          position: relative;
          .tempWrap {
            overflow: hidden;
            position: relative;
            height: 390px;
            ul {
              top: 0px;
              position: relative;
              padding: 0px;
              margin: 0px;
              transition: all 0.3s;
            }
            li {
              height: 130px;
              background: url("~@/assets/img/product/20190806145909264.jpg");
              left: 0;
              width: 97%;
              top: 9px;
              bottom: 15px;
              background-size: contain;
              border: 1px solid #f2f2f2;
              background-repeat: no-repeat;
              background-position: center center;
              //   margin-bottom: 5px;
            }
            .on {
              border: 2px solid #0068b7;
            }
          }
        }
      }
    }
    .right-info {
      float: right;
      width: 39.2%;
      text-align: left;
      h1 {
        padding: 7% 0;
        color: #0068b7;
        font-size: 26px;
        margin: 0;
        border-bottom: 1px solid #e7e8e8;
      }
      p {
        line-height: 30px;
        color: #999;
        margin-bottom: 10%;
        padding: 5% 0;
        border-bottom: 1px solid #e7e8e8;
      }
      a {
        display: inline-block;
        border: 1px solid #fdf3f2;
        padding: 10px 5%;
        color: #0068b7;
        transition: 0.4s;
        &:hover {
          background: #0068b7;
          color: rgb(245, 245, 245);
        }
      }
    }
  }
  .product-tab {
    max-width: 1200px;
    margin: 0 auto;
    color: #666;
    .product-tab-info {
      padding-top: 3%;
      margin: 0 auto;

      p {
        &:first-child {
          font-weight: bold;
        }
        margin: 1rem 0;
        img {
          width: 100%;
        }
      }
    }
    h2 {
      color: #0068b7;
      font-size: 24px;
      margin-top: 3%;
    }
    .table-info {
      padding: 1% 1% 3% 1%;
      .th-1 {
        // color: #333;
        // background: #eee;
        // width: 12.5%;
        // text-align: right;
      }

      th,
      td {
        text-align: left;
        outline: 1px solid #d8e0e5;
        border-collapse: collapse;
        line-height: 24px;
        padding: 15px 30px 15px 35px;
        &:nth-child(odd) {
          color: #333;
          background: #eee;
          width: 20.5%;
          text-align: right;
        }
        word-wrap: break-word;
    white-space: normal;
    // word-break: break-all;
      }
      th {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
}
@media (max-width:426px) {
  .product-detail .product-container .right-info,
  .product-detail .product-container .product-container-list{
    width: 100%;
  }
  .product-detail .product-container .right-info{
    h1{
      padding: 20px;
      text-align: center;
    }
    a{
      display: none;
    }
    p{
      text-indent: 28px;
    }
  }
  .product-detail .product-container .product-container-list{
    height: 274px;
  }
  .product-detail .product-container .product-container-list .img-copy-list .db .tempWrap{
    height: 200px;
    overflow: auto;
  }
  .product-detail .product-container .product-container-list .img-copy-list .db .tempWrap li{
    height: 50px;
  }
  .product-detail .product-tab .table-info th[data-v-7b9b6120], .product-detail .product-tab .table-info td{
    padding: 2%;
  }



}
</style>
